import Login from "./views/_Auth/Login";
import React from "react";

const LazyLogout = React.lazy(() => import('./views/_Auth/Logout'));
const LazyUsersList = React.lazy(() => import('./views/Users/UsersList'));
const LazyUsersForm = React.lazy(() => import('./views/Users/UsersForm'));
const LazyEventList = React.lazy(() => import('./views/Events/EventsList'));
const LazyEventForm = React.lazy(() => import('./views/Events/EventForm'));
const LazySupplierList = React.lazy(() => import('./views/Suppliers/SuppliersList'));
const LazySupplierForm = React.lazy(() => import('./views/Suppliers/SupplierForm'));
const LazyNewsList = React.lazy(() => import('./views/News/NewsList'));
const LazyNewsForm = React.lazy(() => import('./views/News/NewsForm'));
const LazyConcessionnairesList = React.lazy(() => import('./views/Concessionnaires/ConcessionnairesList'));
const LazyTicketScan = React.lazy(() => import('./views/Scan/TicketScan'));
const LazyDashboard = React.lazy(() => import('./views/Dashboard/Dashboard'));


const LazyHome = React.lazy(() => import('./views/Home/Home'));

export interface RoutesInterface {
    path: string;
    element: React.ReactNode;
    index?: boolean;
    description?: string;
    children?: RoutesInterface[];
    role?: string | string[];
    noRoleRedirectUri?: string;
}

export const unprotectedRoutes: RoutesInterface[] = [
    {
        path: 'login',
        description: 'Page de connexion',
        element: <Login/>,
    },
]

export const protectedRoutes: RoutesInterface[] = [

    {
        path: 'logout',
        description: 'Déconnexion',
        element: <LazyLogout/>,
    },
    {
        index: true,
        path: '',
        description: 'Home',
        element: <LazyHome/>,
    },
    {
        path: '/users',
        description: 'Liste des utilisateur',
        role: ['ROLE_ADMIN'],
        element: <LazyUsersList/>,
    },
    {
        path: '/users/new',
        description: 'Créer un utilisateur',
        role: ['ROLE_ADMIN'],
        element: <LazyUsersForm/>,
    },
    {
        path: '/users/:id/edit',
        description: 'Edit utilisateur',
        role: ['ROLE_ADMIN'],
        element: <LazyUsersForm/>,
    },
    {
        path: '/events',
        description: 'Liste des évènements',
        role: ['ROLE_ADMIN'],
        element: <LazyEventList/>,
    },
    {
        path: '/events/new',
        description: 'Create event',
        role: ['ROLE_ADMIN'],
        element: <LazyEventForm/>,
    },
    {
        path: '/events/:id/edit',
        description: 'Edit Event',
        role: ['ROLE_ADMIN'],
        element: <LazyEventForm/>,
    },
    {
        path: '/suppliers',
        description: 'Liste des fournisseurs',
        role: ['ROLE_ADMIN'],
        element: <LazySupplierList/>,
    },
    {
        path: '/suppliers/new',
        description: 'Create fournisseurs',
        role: ['ROLE_ADMIN'],
        element: <LazySupplierForm/>,
    },
    {
        path: '/suppliers/:id/edit',
        description: 'Edit fournisseurs',
        role: ['ROLE_ADMIN'],
        element: <LazySupplierForm/>,
    },
    {
        path: '/news/new',
        description: 'Create news',
        role: ['ROLE_ADMIN'],
        element: <LazyNewsForm/>,
    },
    {
        path: '/news',
        description: 'Liste des news',
        role: ['ROLE_ADMIN'],
        element: <LazyNewsList/>,
    },
    {
        path: '/concessionnaires',
        description: 'Liste des concessionnaires',
        role: ['ROLE_ADMIN'],
        element: <LazyConcessionnairesList/>,
    },
    {
        path: '/scan',
        description: 'Scan des tickets',
        role: ['ROLE_ADMIN', 'ROLE_HOTESSE', 'ROLE_FOURNISSEUR'],
        element: <LazyTicketScan/>,
    },
    {
        path: '/dashboard',
        description: 'Dashboard',
        role: ['ROLE_ADMIN'],
        element: <LazyDashboard/>,
    },

];