import {AuthContextInterface} from "./context/auth/AuthContextInterface";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import {Article, Equalizer, EventNote, Groups, QrCodeScannerRounded, Remove} from "@mui/icons-material";

/**
 * Type de menu gauche
 */
export const MENU_GAUCHE_ICONES = 'MENU_ICONE';
export const MENU_GAUCHE_LISTE = 'MENU_LISTE';
export let menuGaucheType = MENU_GAUCHE_LISTE;  //type de menu (icon façon goat, ou liste façon oowi)
export const menuGaucheWidth = 190;             //taille en largeur du menu de gauche (uniquement pour menu type liste)
export const defaultMenuIcon = {icon: Remove};

/**
 * Liste des pages à inclure dans le menu de gauche et haut
 */
interface pageLink {
    id: number;
    title: string;
    path: string;
    icon?: any;
    role?: string | string[];
    subLinks?: pageSubLink[];  //ne fonctionne que pour le menu Liste
}

interface pageSubLink {
    id: number;
    title: string;
    path: string;
    role?: string;
}

export const pagesLinks: pageLink[] = [
    {id: 0, title: 'Agenda', path: '/events', icon: EventNote, role: "ROLE_ADMIN"},
    {id: 1, title: 'Fournisseurs', path: '/suppliers', icon: Groups, role: "ROLE_ADMIN"},
    {id: 2, title: 'Notifications', path: '/news', icon: Article, role: "ROLE_ADMIN"},
    {id: 3, title: 'Concessionnaires', path: '/concessionnaires', icon: SupervisedUserCircleIcon, role: "ROLE_ADMIN"},
    {id: 4, title: 'Utilisateurs', path: '/users', icon: SupervisedUserCircleIcon, role: "ROLE_ADMIN"},
    {
        id: 5,
        title: 'Scan',
        path: '/scan',
        icon: QrCodeScannerRounded,
        role: ["ROLE_ADMIN", "ROLE_FOURNISSEUR", "ROLE_HOTESSE"]
    },
    {id: 6, title: 'Dashboard', path: '/dashboard', icon: Equalizer, role: ["ROLE_ADMIN"]},
    /*
    {
        id: 1, title: 'Page 1', path: '/dashboard', subLinks: [
            {id: 10, title: 'SUB Page 0', path: '/users'},
            {id: 11, title: 'SUB Page 1', path: '/users'},
        ]
    },
    */

];

/**
 * Liste des liens dans le menu utilisateur
 */
interface UserMenuLink {
    title: string;
    path: string;
}

export const userMenuLinks: UserMenuLink[] = [
    {title: 'Deconnexion', path: '/logout'},
];

/** Texte du footer, si laissé à null on aura pas de footer */
export const footerText = 'Copyright © 2023 MLD'; //si null, pas de footer

/**
 * Titre du site, affiché à gauche du header
 * à faire évoluer quand on voudra un logo
 */
export const titreMenuHaut = 'Kramp Open 2023';

/**
 * Fonction de renvoi du nom de l'utilisateur connecté
 * à modifier en fonction de la structure des objet User contenus dans AuthContextInterface
 * @param authContext
 */
export const getConnectedserName = (authContext: AuthContextInterface): string => {
    return authContext.user?.firstName + ' ' + authContext.user?.lastName
}