import {AxiosResponse} from "axios";
import {useState} from "react";

const useForm = <T extends object>(initialFormData: T, submitFunction: (formData: T) => Promise<AxiosResponse>) => {
    const [formData, setFormData] = useState<T>(initialFormData);
    const [initialForm, setInitialForm] = useState<T>(initialFormData);

    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
    const [err, setErr] = useState<any>({});
    const [forceIsModified, setForceIsModified] = useState<boolean>(false);
    const [errorHandler, setErrorHandler] = useState<(response: AxiosResponse) => {
        [x: string]: string
    }>(() => (response: AxiosResponse) => {
        return response.data
    });
    const [postNotFoundCallback, setPostNotFoundCallBack] = useState<(response: AxiosResponse) => any>
    ((response: AxiosResponse) => {
        return response;
    });
    const [postSuccessCallback, setPostSuccessCallback] = useState<(response: AxiosResponse) => any>
    ((response: AxiosResponse) => {
        return response;
    });

    /**
     * Réinitialise le formulaire
     * @param data
     */
    const updateInitialForm = (data: T): void => {
        setInitialForm(data);
        setFormData(data);
    };

    /**
     * Maj une valeur du formulaire
     * @param clef
     * @param value
     */
    const updateFormChamp = (clef: string, value: any): void => {
        setFormData({...formData, [clef]: value});
    };

    /**
     * maj plusieurs valeurs d'un coup
     * @param data
     */
    const updateFormChamps = (data: { [x: string]: any }): void => {
        setFormData({...formData, ...data});
    };

    /**
     * Vérifie si le formulaire a été modifié
     */
    const isModifiedForm = (): boolean => {
        if (forceIsModified) {
            setForceIsModified(false);
            return true;
        }
        return (
            Object.keys(formData).length > 0 &&
            JSON.stringify(initialForm) !== JSON.stringify(formData)
        );
    };

    const forceIsModifiedForm = (): void => {
        setForceIsModified(true);
    }

    /**
     * Raz le formulaire
     */
    const raz = (): void => {
        setFormData(initialForm);
    }

    /**
     * Submit du formulaire
     */
    const handleSubmit = () => {
        setErr({});
        if (!isModifiedForm()) {
            return;
        }

        setIsSubmiting(true);
        submitFunction(formData).then((response: AxiosResponse) => {
            switch (response.status) {
                case 200:
                case 201:
                case 204:
                    if (postSuccessCallback) {
                        postSuccessCallback(response);
                    }
                    break;
                case 404:
                    if (postNotFoundCallback) {
                        postNotFoundCallback(response);
                    }
                    break;
                case 400:
                case 401:
                default:
                    setErr(errorHandler(response));
                    break;
            }
            setIsSubmiting(false);
        });
    };

    return {
        formData,
        initialForm,
        err,
        isSubmiting,
        setErr,
        handleSubmit,
        setErrorHandler,
        setPostNotFoundCallBack,
        setPostSuccessCallback,
        updateFormChamp,
        updateFormChamps,
        updateInitialForm,
        setInitialForm,
        raz,
        setIsSubmiting,
        forceIsModifiedForm
    };
};

export default useForm;