import {createTheme} from "@mui/material";

const mainBackgroundColor = "#eee";
export const menusBackgroundColor = "#AE0000";
export const menusSublistBackgroundColor = "#97090A";
export const menusTextColor = "#eee";

const _Theme = createTheme({
    palette: {
        background: {
            default: mainBackgroundColor,
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: menusBackgroundColor,
                },
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    backgroundColor: menusBackgroundColor,
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: menusTextColor,
                }
            }
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#FFF",
                    color: "#000",
                }
            }
        }
    },
});

export default _Theme;