import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {AxiosResponse} from "axios";
import {Card, CardActions, CardContent, CardMedia, Container} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import {useAuthContext} from "../../context/auth/authContext";
import {useAuthService} from "../../services/auth/useAuthService";
import useForm from "../../hooks/form/useForm";
import LoginFormInterface from "../../services/auth/LoginFormInterface";
import {FormSubmitButton} from "../../components/form/buttons/FormSubmitButton";
import {FormProvider} from "../../context/form/formContext";
import EmailInput from "../../components/form/inputs/EmailInput";
import PasswordInput from "../../components/form/inputs/PasswordInput";
import AlertErreur from "../../components/form/error/AlertErreur";
import FormBox from "../../components/form/FormBox";
import logoLogin from "../../assets/logoLogin.png";

const Login = () => {
    const auth = useAuthContext();
    const navigate = useNavigate();
    const authService = useAuthService(auth);
    const form = useForm<LoginFormInterface>({source: "web"}, (data: LoginFormInterface) => {
        return authService.loginUser(data);
    });

    /**
     * init de la fonction d'error handling du form
     */
    useEffect(() => {
        form.setErrorHandler(() => (response: AxiosResponse): { [x: string]: string } => {
            if (response && response.status === 401) {
                return {message: 'Identifiant ou mot de passe incorrect'};
            }

            if (response && response.status === 400 && response.data && response.data.message) {
                if (response.data.message === "The key 'email' must be provided.") {
                    return {username: "L'email est obligatoire"};
                }
                if (response.data.message === "The key 'password' must be provided.") {
                    return {password: "Le mot de passe est obligatoire"};
                }
            }
            return response ? response.data : {};
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Redirect vers home quand on aura un token
     */
    useEffect((): void => {
        if (auth.token) {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.token]);

    return (
        <FormProvider form={form}>
            <Container>
                <div className="page-center">
                    <Card sx={{minWidth: 275}}>
                        <CardMedia
                            component="img"
                            alt="logo"
                            height="105"
                            image={logoLogin}
                        />
                        <CardContent>
                            <FormBox>
                                <Grid2 container spacing={2} display={"flex"} flexDirection={"column"}>
                                    <EmailInput nom={"email"}
                                                label={"Email"}
                                                variant={"standard"}
                                    />
                                    <PasswordInput nom={"password"}
                                                   label={"Mot de passe"}
                                                   variant={"standard"}
                                    />
                                </Grid2>
                            </FormBox>
                            <AlertErreur err={form.err.message}/>
                        </CardContent>
                        <CardActions>
                            <Box flexGrow={1} display={"flex"} justifyContent={"flex-end"}>
                                <FormSubmitButton variant={"text"}> Connexion </FormSubmitButton>
                            </Box>
                        </CardActions>
                    </Card>
                </div>
            </Container>
        </FormProvider>
    );
}

export default Login;